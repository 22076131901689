<template>
  <div class="kyzs_box" ref="kyzs_box">
    <h1>{{ routeData.title3?routeData.title3:routeData.title2?routeData.title2:routeData.title1}}</h1>
    <div class="kyzs_nav" v-for="(item,i) in kyzs_list.list" :key="i" @click="pushArticle_xq(item)">
      <section>
        <div class="imgs_"><img :src="baseUrl + item.thumb" alt=""></div>
        <nav>
          <i></i>
          <div class="center_text">
            <h1>{{item.author}}:</h1>
            <span>{{item.title}}</span>
            <p>{{item.description}}</p>
          </div>
        </nav>
      </section>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
    </div>
  </div>
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import * as axios from '@/api/API.js'
import {ref,reactive,onMounted,defineEmits, onUnmounted} from 'vue'
import { useRoute,useRouter } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
const route = useRoute()
const router = useRouter()
const baseUrl = process.env.VUE_APP_BASE_URL

let routeData = JSON.parse(base64_.decode(route.params.obj))

const from = reactive({
  page:1,
  limit:10,
  total:0,
  type_id:routeData.type_id
})
const kyzs_box = ref(null)
const kyzs_list = reactive({
  list:[]
})
// 获取文章列表
const getwz_List = async () => {
  const {data:res} = await axios.getHttp("/api/article",from)
  if(res.code == 200){
    // 数据请求成功 把动画值设置为正常显示
    kyzs_box.value.style.opacity = 1
    kyzs_box.value.style.transform = 'translateY(0)'
    from.total = res.data.total
    kyzs_list.list = res.data.data
  }
}
// getwz_List()

// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
  const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
    // sessStor?from.page = sessStor:from.page = 1
    if(sessStor){
      from.page = sessStor
    }else{
      from.page = 1
    }
    from.type_id = routeData.type_id
    getwz_List()
}
editFrompage()
// 分页
const pageChanges = (ee) => {
  from.page = ee
  window.sessionStorage.setItem('page',ee)
  getwz_List()
}
// 页面加载给予动画
onMounted(() => {
  kyzs_box.value.style.transform = 'translateY(500px)'
  kyzs_box.value.style.opacity = 0
})

// 点击跳转到详情
let pushArticle_xq = (item) => {
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
    article_wz_id:item.id,
    title1:item.type_name,
    type_id:item.type_id,
    index: routeData.index
    }))}
  })
}

onUnmounted(() => {
  if(!route.params.obj) return
  if(!JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
     window.sessionStorage.setItem('page',1)
  }
})
</script> 

<style lang="less" scoped>
@maxw:128rem;
.kyzs_box{
  width: 100%;
  transition: ease .8s;
  cursor: pointer;
>h1{
    font-size: 39/@maxw;
    font-family: fantBold;
      font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 10/@maxw 0;
    cursor: pointer;
  }
  .kyzs_nav{
    // padding: 0 50/@maxw;
    margin-top: 40/@maxw;
    width: 100%;
    >section{
      display: flex;
      justify-content: space-between;
      img{
        width: 230/@maxw;
        height: 252/@maxw;
        background: #CBC9C9;
        transition: ease .3s;
        object-fit: cover;
      }
      nav{
        position: relative;
        width: 874/@maxw;
        height: 252/@maxw;
        background-image: url("../../../assets/ejkybj2.png");
        background-size: 100% 100%;
        cursor: pointer;
        transition: ease .3s;
        margin-left: 20/@maxw;
        >i{
          position: absolute;
          top: 25/@maxw;
          left: 35/@maxw;
          width: 50/@maxw;
          height: 50/@maxw;
          background-image: url("../../../assets/ejky.png");
          background-size: 100% 100%;
        }
        >.center_text{
          margin-left: 110/@maxw;
          width: 715/@maxw;
          height: 100%;
          color: #174994;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transition: ease .3s;
          h1{
            margin:-10/@maxw 0 15/@maxw 0;padding: 0;
            font-size: 32/@maxw;
            font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
            font-weight: 800;
          }
          span{
            width: 610/@maxw;
            font-size: 31/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          p{
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 300;
            font-size: 16/@maxw;
            color: #5F5F5F;
            margin: 0;padding: 0;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            transition: ease .3s !important;
            margin-top: 21/@maxw;
          }
        }
        .time_nav{
          position: absolute;
          right: 40/@maxw;
          top: 25/@maxw;
          width: 103/@maxw;
          height: 103/@maxw;
          border: 1/@maxw solid #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          transition: ease .3s;
          >a{
            font-size: 40/@maxw;
            font-weight: 550;
          } 
          >span{
            font-size: 20/@maxw;
          }
        }
      }
    }
  }
  .kyzs_nav:hover{
    >section{
      img{
        transform: scale(1.02);
      }
      nav{
        background-image: url("../../../assets/ejkybj1.png");
        >.center_text{
          color: #fff !important;
          p{
            color: #fff !important;
          }
        }
      }
      .time_nav{
        border: 1/@maxw solid #D1BAA6;
        color: #D1BAA6;;
      }
    }
  }
}
.pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
  @media screen and (max-width:750px) {
    @maxw_:750/100vw;
    .kyzs_box{
      margin: 0 !important;
      >h1{
        font-size: 35/@maxw_ !important;
        border-bottom:2/@maxw_ solid #174994;
      }
      .kyzs_nav{
        >section{
         .imgs_{
          
          margin: 0;
          padding: 0;
          img{
          width: 230/@maxw_ !important;
          height: 252/@maxw_ !important;
          background: #CBC9C9;
          transition: ease .3s;
        }
         }
        nav{
          
          flex: 1;
        // width: 454/@maxw_;
        height: 252/@maxw_;
        background-image: url("../../../assets/ejkybj2.png");
        margin-left: 20/@maxw;
        >i{
          position: absolute;
          top: 25/@maxw_;
          left: 15/@maxw_;
          width: 40/@maxw_;
          height: 40/@maxw_;
          background-image: url("../../../assets/ejky.png");
          background-size: 100% 100%;
        }
        >.center_text{
          margin-left: 60/@maxw_;
          width: 700/@maxw_;
          height: 100%;
          h1{
            margin:-10/@maxw_ 0 15/@maxw_ 0;padding: 0;
            font-size: 32/@maxw_;
          }
          span{
            width: 350/@maxw_;
            font-size: 31/@maxw_;
          }
          p{
            width: 370/@maxw_;
            font-size: 23/@maxw_;
            margin-top: 21/@maxw_;
          }
        }
      }
        }
      }
    }
  }
</style>